// BinderContext.js
import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import { getBinders } from "../../../services/firestoreService"; // Adjust the import path as needed
//import { getAuth } from "firebase/auth";

const BinderContext = createContext();

export const useBinders = () => {
  const context = useContext(BinderContext);
  if (context === undefined) {
    throw new Error("useBinders must be used within a BinderProvider");
  }
  return context;
};

export const BinderProvider = ({ children, userId }) => {
  const [binders, setBinders] = useState([]);
  const [loading, setLoading] = useState(true);

  const addBinder = (newBinder) => {
    setBinders((prevBinders) => [...prevBinders, newBinder]);
  };

  const removeBinder = (binderId) => {
    setBinders((prevBinders) =>
      prevBinders.filter((binder) => binder.id !== binderId)
    );
  };

  const updateBinderList = (binderId, updatedData) => {
    setBinders((prevBinders) => {
      console.log("Before update:", prevBinders); // Debugging

      const updatedBinders = prevBinders.map((binder) =>
        binder.id === binderId ? { ...binder, ...updatedData } : binder
      );

      console.log("After update:", updatedBinders); // Debugging
      return updatedBinders;
    });
  };

  const fetchBinders = useCallback(async (userId) => {
    try {
      console.log("Fetching binders for userId:", userId);
      setLoading(true);
      const binderData = await getBinders(userId);
      setBinders(binderData);
      console.log("inside fetchBinders: " + binderData);
   //  console.log("Binder state after fetch: ", binders);
    } catch (error) {
      console.error("Error fetching binders: ", error);
      // Optionally handle the error, e.g., show a notification
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log("useEffect triggered in BinderProvider with userId:", userId);
    if (userId) {
      fetchBinders(userId);
    }
  }, [userId, fetchBinders]);

  useEffect(() => {
    console.log("Binder state updated: ", binders);
  }, [binders]);

  

  return (
    <BinderContext.Provider
      value={{ binders, loading, addBinder, removeBinder, updateBinderList }}
    >
      {children}
    </BinderContext.Provider>
  );
};

export default BinderProvider;
