import React from "react";
import "./EditBoard.css";

const EditBoard = ({
  title,
  description,
  setTitle,
  setDescription,
  onSubmit,
}) => {
  return (
    <form
      className="edit-board-form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="edit-board-form-title">Edit Board</div>
      <label className="edit-board-label" htmlFor="board-title">
        Title:
        <input
          id="board-title"
          className="edit-board-input"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </label>
      <label className="edit-board-label" htmlFor="board-description">
        Description:
        <textarea
          id="board-description"
          className="edit-board-textarea"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </label>
      <button className="edit-board-submit" type="submit">
        Update Board
      </button>
    </form>
  );
};

export default EditBoard;
