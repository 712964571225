import React, { useEffect } from "react";
import "./Modal.css";
import feather from "feather-icons";

function Modal({ children, closeModal }) {
  useEffect(() => {
    feather.replace();
  }, []);

  return (
    <div className="modal-backdrop" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {children}
        <button onClick={closeModal} className="modal-close-button">
          <i data-feather="x-square"></i>
        </button>
      </div>
    </div>
  );
}

export default Modal;
