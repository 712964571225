import { db } from "../firebase";
import {
  collection,
  query,
  where,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

export const createBoard = async (boardData) => {
  try {
    const docRef = await addDoc(collection(db, "boards"), boardData);
    console.log(
      "createBoard successful - Document written with ID: ",
      docRef.id
    );
    return docRef.id;
  } catch (error) {
    console.error("createBoard failure - Error adding document: ", error);
  }
};

export const getBoards = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "boards"));
    const boards = [];
    querySnapshot.forEach((doc) => {
      boards.push({ ...doc.data(), id: doc.id });
    });
    console.log("getBoards successful - Boards: ", boards);
    return boards;
  } catch (error) {
    console.error("getBoards failure - Error getting boards: ", error);
  }
};

export const getBoardsByBinderId = async (binderId) => {
  try {
    const q = query(
      collection(db, "boards"),
      where("binderId", "==", binderId)
    );
    const querySnapshot = await getDocs(q);
    const boards = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("getBoardsByBinderId successful - Boards: ", boards);
    return boards;
  } catch (error) {
    console.error(
      "getBoardsByBinderId failure - Error getting boards: ",
      error
    );
    throw error;
  }
};

export const getBoard = async (boardId) => {
  try {
    const boardDocRef = doc(db, "boards", boardId);
    const docSnapShot = await getDoc(boardDocRef);
    if (docSnapShot.exists()) {
      return { id: docSnapShot.id, ...docSnapShot.data() };
    } else {
      console.log("getBoard failure - No such document!");
      return null;
    }
  } catch (error) {
    console.error("getBoard failure - Error getting board: ", error);
  }
};

export const updateBoard = async (boardId, boardData) => {
  try {
    const boardDocRef = doc(db, "boards", boardId);
    await updateDoc(boardDocRef, boardData);
    console.log("updateBoard successful - Board updated: ", boardId);
    return { id: boardId, ...boardData };
  } catch (error) {
    console.error("updateBoard failure - Error updating board: ", error);
    throw error;
  }
};

export const deleteBoard = async (boardId) => {
  try {
    const boardDocRef = doc(db, "boards", boardId);
    await deleteDoc(boardDocRef);
    console.log("deleteBoard successful - Board deleted: ", boardId);
  } catch (error) {
    console.error("deleteBoard failure - Error deleting board: ", error);
    throw error;
  }
};

export const createUserProfile = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = doc(db, "users", userAuth.uid);
  const snapshot = await getDoc(userRef);

  if (!snapshot.exists()) {
    const { email } = userAuth;
    const { firstName, lastName } = additionalData;
    const createdAt = new Date();
    const tokenBalance = 10; // Default token balance for new users

    try {
      await setDoc(userRef, {
        firstName,
        lastName,
        email,
        tokenBalance,
        createdAt,
      });
    } catch (error) {
      console.error("Error creating user profile", error.message);
    }
  }
};

export const fetchUserProfile = async (uid) => {
  if (!uid) return null;

  const userRef = doc(db, "users", uid);
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    return null;
  }
};

export const updateUserProfile = async (uid, updatedData) => {
  if (!uid) return;

  const userRef = doc(db, "users", uid);

  try {
    await updateDoc(userRef, updatedData);
  } catch (error) {
    console.error("Error updating user profile", error.message);
  }
};

export const recordTokenTransaction = async (userId, amount, type) => {
  const transaction = {
    userId,
    amount,
    type,
    timestamp: serverTimestamp(),
  };
  await addDoc(collection(db, "tokenTransactions"), transaction);
};

export const createBinder = async (userId, binderName, description = "") => {
  try {
    const binderDoc = {
      name: binderName,
      description: description,
      ownerUserId: userId,
      boards: [],
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };

    const docRef = await addDoc(collection(db, "binders"), binderDoc);
    return docRef.id;
  } catch (error) {
    console.error("Error creating binder: ", error);
    throw error;
  }
};

export const getBinder = async (binderId) => {
  try {
    const docRef = doc(db, "binders", binderId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw new Error("No such binder!");
    }
  } catch (error) {
    console.error("Error getting binder: ", error);
    throw error;
  }
};

export const getBinders = async (userId) => {
  try {
    const q = query(
      collection(db, "binders"),
      where("ownerUserId", "==", userId)
    );
    const querySnapshot = await getDocs(q);
    let binders = [];
    querySnapshot.forEach((doc) => {
      binders.push({ id: doc.id, ...doc.data() });
    });
    return binders;
  } catch (error) {
    console.error("Error getting binders: ", error);
    throw error;
  }
};

export const updateBinder = async (binderId, updatedData) => {
  try {
    const binderRef = doc(db, "binders", binderId);
    await updateDoc(binderRef, {
      ...updatedData,
      updatedAt: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error updating binder: ", error);
    throw error;
  }
};

export const deleteBinder = async (binderId) => {
  try {
    await deleteDoc(doc(db, "binders", binderId));
  } catch (error) {
    console.error("Error deleting binder: ", error);
    throw error;
  }
};
