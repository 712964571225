// BinderList.js
import React from "react";
import { useBinders } from "../context/BinderContext"; // Adjust the import path
//import Binder from "../Binder/Binder";
import { Link } from "react-router-dom";
import "./BinderList.css";

function BinderList() {
  const { binders } = useBinders();

  console.log("in BinderList binders: ", binders);

  if (binders.length === 0) {
    return (
      <div>
        No binders found
        <Link to="/create-binder">
          <button className="add-binder-button">Create New Binder</button>
        </Link>
      </div>
    );
  }

  return (
    <div>
      <div className="binder-list-container">
        <div className="add-binder-button-container">
          <Link to="/create-binder">
            <button className="add-binder-button">Create New Binder</button>
          </Link>
        </div>
        <h3>Binders</h3>

        {binders.map((binder) => (
          <div key={binder.id} className="binder-item">
            <Link to={`/binder/${binder.id}`}>{binder.name}</Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BinderList;
