import React from "react";
import AuthForm from "../Auth/AuthForm";
import "./SplashPage.css"; 

const SplashPage = () => {
   return (
    <div className="splash-container">
      <div className="splash-content">
        <div className="splash-info">
          <h1>Unleash Creativity with IDEO</h1>
          <p className="intro">Empower your theater productions with visionary design collaboration.</p>
          <div className="features">
            <div className="feature">
              <h2>AI-Powered Visualization</h2>
              <p>Create stunning visuals from textual descriptions to kickstart your creative process.</p>
            </div>
            <div className="feature">
              <h2>Intuitive Mood Boards</h2>
              <p>Craft and manage your project's aesthetic with easy-to-use mood boards.</p>
            </div>
            <div className="feature">
              <h2>Secure Collaboration</h2>
              <p>Collaborate with confidence with secure, private access to your designs.</p>
            </div>
          </div>
        </div>
        <div className="splash-auth">
          <AuthForm />
        </div>
      </div>
    </div>

  );
};

export default SplashPage;
