import React, { useState, useEffect } from "react";
import { createBinder } from "../../../services/firestoreService";
import { getAuth } from "firebase/auth";
import "./CreateBinder.css";
import { useNavigate } from "react-router-dom";
import { useBinders } from "../context/BinderContext";

function CreateBinder({ setBinders }) {
  const [binderName, setBinderName] = useState("");
  const [description, setDescription] = useState("");
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setUserId(user.uid);
    }
  }, []);

  const { addBinder } = useBinders();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!binderName || !userId) {
      // Handle empty binder name case
      return;
    }
    try {
      const binderId = await createBinder(userId, binderName, description);
      addBinder({ id: binderId, name: binderName, description });
      // Optionally, clear the form or navigate the user elsewhere
      setBinderName("");
      setDescription("");
      navigate(`/binder/${binderId}`);
    } catch (error) {
      console.error("Error creating binder:", error);
      // Handle the error appropriately
    }
  };

  return (
    <div className="create-binder-container">
      <h2 className="create-binder-title">Create Binder</h2>
      <form className="create-binder-form" onSubmit={handleSubmit}>
        <label>
          Binder Name:
          <input
            type="text"
            value={binderName}
            onChange={(e) => setBinderName(e.target.value)}
            required
          />
        </label>
        <br />
        <label>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>
        <br />
        <button type="submit">Create Binder</button>
      </form>
    </div>
  );
}

export default CreateBinder;
