import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createBoard } from "../../../services/firestoreService";
import { getAuth } from "firebase/auth";
import "./CreateBoard.css";

const CreateBoard = ({ setBoards }) => {
  const [boardData, setBoardData] = useState({ title: "", description: "" });
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve the binderId from the location state
  const { binderId } = location.state || {};

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBoardData({ ...boardData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;
    if (user && binderId) {
      const newBoardData = {
        ...boardData,
        userId: user.uid,
        binderId,
      };

      try {
        const newBoardId = await createBoard(newBoardData);
        console.log("Board created: ", newBoardId);
        console.log("Board created in binder: ", binderId);
        // Update the boards state in the App component
        setBoards((prevBoards) => [
          ...prevBoards,
          { ...newBoardData, id: newBoardId },
        ]);
        // Navigate to the new board
        // navigate(`/board/${newBoardId}`);

        // Navigate to the binder view or board view
        navigate(`/binder/${binderId}`);
      } catch (error) {
        console.error("Error creating board:", error);
        alert("Error creating board");
      }
    } else {
      alert("You must be signed in and in a binder to create a board");
    }
  };

  return (
    <div className="create-new-board-container">
      <h2 className="create-board-header">Create New Board</h2>
      <form className="create-board-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={boardData.title}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            value={boardData.description}
            onChange={handleInputChange}
          />
        </div>
        <button type="submit">Create Board</button>
      </form>
    </div>
  );
};

export default CreateBoard;
