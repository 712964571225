import React, { useState } from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

function AuthForm() {
  const [activeTab, setActiveTab] = useState("signin"); // Default to the sign-in tab

  return (
    <div className="auth-container">
      {/* Tab navigation */}
      <div className="tabs">
        <button
          className={`tab ${activeTab === "signin" ? "active" : ""}`}
          onClick={() => setActiveTab("signin")}
        >
          Sign In
        </button>
        <button
          className={`tab ${activeTab === "signup" ? "active" : ""}`}
          onClick={() => setActiveTab("signup")}
        >
          Sign Up
        </button>
      </div>

      {/* Conditional rendering of forms */}
      {activeTab === "signin" && (
       <SignIn />
      )}

      {activeTab === "signup" && (
       <SignUp />
      )}
    </div>
  );
}

export default AuthForm;