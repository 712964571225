import React, { useState } from 'react';
import { signIn } from '../../auth-service'; 
import './Auth.css';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signIn(email, password);
      // Navigate to home or dashboard page upon successful login
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="sign-in-container">
      <h2 className="sign-in-header">Sign In</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form className="sign-in-form" onSubmit={handleSubmit}>
        <input
        className="sign-in sign-in-email"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
        className="sign-in sign-in-password"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="sign-in-button" type="submit">Sign In</button>
      </form>
    </div>
  );
};

export default SignIn;
