import "./ImageCard.css";
import React, { useState } from "react";
import Modal from "../Modal/Modal";

function ImageCard({ image }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  if (!image) return null;

  
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!image.url) return null;
  return (
    <div className="image-card">
      <img
        src={image.url}
        alt="Generated"
        className="image-card-img"
        onClick={openModal}
      />
      {isModalOpen && (
        <Modal closeModal={closeModal}>
          <img src={image.url} alt="Generated" className="modal-image" />
          <div className="image-metadata">
            {image.metadata.prompt && <p>Prompt: {image.metadata.prompt}</p>}
            {image.metadata.model && <p>Model: {image.metadata.model}</p>}
            {image.metadata.dimensions && (
              <p>Size: {image.metadata.dimensions}</p>
            )}
            {image.metadata.date && (
              <p>Date: {new Date(image.metadata.date).toLocaleString()}</p>
            )}
            {image.metadata.revisedPrompt && (
              <p>Revised Prompt: {image.metadata.revisedPrompt}</p>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ImageCard;
