import React, { useState, useEffect } from "react";
import "./UserProfile.css";
// Import Firebase services if needed in the future
// import { auth, firestore } from '../path-to-your-firebase-config';
import {
  fetchUserProfile,
  updateUserProfile,
} from "../../services/firestoreService";

const UserProfile = ({ user }) => {
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    tokenBalance: 0,
  });
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (user) {
      // Call the function to fetch user profile
      fetchUserProfile(user.uid)
        .then((userProfile) => {
          if (userProfile) {
            setProfile(userProfile);
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile", error);
        });
    }
  }, [user]);

  const handleSaveProfile = () => {
    // Call the function to update user profile
    updateUserProfile(user.uid, profile)
      .then(() => {
        setEditMode(false);
        console.log("Profile updated successfully.");
      })
      .catch((error) => {
        console.error("Error updating user profile", error);
      });
  };

  // Handle change for form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  if (!user) {
    return <div>Please sign in to view your profile.</div>;
  }

  return (
    <div className="user-profile">
      <h2>User Profile</h2>
      {editMode ? (
        // If in edit mode, show the form
        <form onSubmit={handleSaveProfile}>
          <div className="profile-field">
            <label htmlFor="firstName">First Name:</label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              value={profile.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="profile-field">
            <label htmlFor="lastName">Last Name:</label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              value={profile.lastName}
              onChange={handleChange}
            />
          </div>
          {/* Additional fields can be added here if needed */}
          <div className="profile-actions">
            <button type="button" onClick={() => setEditMode(false)}>
              Cancel
            </button>
            <button type="submit">Save Changes</button>
          </div>
        </form>
      ) : (
        // Display user profile
        <>
          <div className="profile-field">
            <label>First Name:</label>
            <span>{profile.firstName}</span>
          </div>
          <div className="profile-field">
            <label>Last Name:</label>
            <span>{profile.lastName}</span>
          </div>
          <div className="profile-field">
            <label>Email:</label>
            <span>{profile.email}</span>
          </div>
          <div className="profile-field">
            <label>Token Balance:</label>
            <span>{profile.tokenBalance}</span>
          </div>
          <button onClick={() => setEditMode(true)}>Edit Profile</button>
        </>
      )}
    </div>
  );
};

export default UserProfile;