import React, { useState, useEffect } from "react";
import "./Board.css";
import { useParams } from "react-router-dom";
import { getBoard, deleteBoard } from "../../services/firestoreService";
import feather from "feather-icons";
import ImagePromptInput from "../ImagePromptInput/ImagePromptInput";
// import { generateImage } from "../../services/openai";
import ImageCard from "../ImageCard/ImageCard";
import { useNavigate } from "react-router-dom";
import {
  updateBoard,
  fetchUserProfile,
  updateUserProfile,
  recordTokenTransaction,
} from "../../services/firestoreService";
// import { downloadImage, uploadImageToStorage} from "../../services/imageService";
import { getAuth } from "firebase/auth";
import EditBoard from "./EditBoard/EditBoard";
const TOKEN_COST = 1;

const Board = () => {
  const [board, setBoard] = useState(null);
  const [boardImages, setBoardImages] = useState([]); // [ {id: 1, url: "https://openai.com/image1.jpg"}, {id: 2, url: "https://openai.com/image2.jpg"}
  const navigate = useNavigate();
  const { boardId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");

  const handleEditButtonClick = () => {
    setEditTitle(board.title);
    setEditDescription(board.description);
    setIsEditing(true);
  };

  const handleUpdateBoard = async () => {
    try {
      const updatedBoardData = {
        title: editTitle,
        description: editDescription,
        // ... any other data you might want to update
      };
      await updateBoard(boardId, updatedBoardData);
      // Update local state to reflect changes
      setBoard(updatedBoardData);
      setIsEditing(false); // Close the edit form
    } catch (error) {
      console.error("Failed to update board: ", error);
    }
  };

  useEffect(() => {
    const fetchBoard = async () => {
      const boardDetails = await getBoard(boardId);

      if (boardDetails) {
        setBoard(boardDetails);
        setBoardImages(boardDetails.images || []);
      } else {
        console.log("No such board!");
      }
    };
    fetchBoard().catch(console.error);
  }, [boardId]);

  const handleDeleteBoard = (boardId) => {
    deleteBoard(boardId)
      .then(() => {
        console.log("Board deleted: ", boardId);
        setBoard(null);
      })
      .catch(console.error);
    navigate("/board-list");
  };
  //Feather icons
  useEffect(() => {
    feather.replace();
  }, [board]);

  const onGenerate = async (prompt, model, size) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User is not authenticated");
      }

      // Fetch the user's profile to check token balance
      const userProfile = await fetchUserProfile(user.uid);
      if (userProfile.tokenBalance < TOKEN_COST) {
        throw new Error("Insufficient tokens");
      }

      // Proceed to deduct tokens and record the transaction
      const newTokenBalance = userProfile.tokenBalance - TOKEN_COST;
      await updateUserProfile(user.uid, { tokenBalance: newTokenBalance });
      await recordTokenTransaction(user.uid, -TOKEN_COST, "image_generation");

      const idToken = await user.getIdToken();
      //console.log("idToken:", idToken);

      const response = await fetch(
        "https://us-central1-ideo-5da59.cloudfunctions.net/imageGenerator", // Make sure this URL is correct
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({ prompt, model, size }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const imageObject = await response.json();
      const updatedImages = [...boardImages, imageObject];
      await updateBoard(boardId, { images: updatedImages });
      setBoardImages(updatedImages);
    } catch (error) {
      console.error("Error calling imageGenerator:", error);
    }
  };
  const boardImagesReversed = [...boardImages].reverse();

  return (
    <div className="board-container">
      {isEditing ? (
        // Render the edit form if isEditing is true
        <EditBoard
          title={editTitle}
          description={editDescription}
          setTitle={setEditTitle}
          setDescription={setEditDescription}
          onSubmit={handleUpdateBoard}
        />
      ) : (
        <>
          {!board && <div className="board-loading">Loading...</div>}
          {board && (
            <div className="board-item">
              <h2 className="board-header">{board.title}</h2>
              <p className="board-description">{board.description}</p>
              <div className="board-top-right">
                <button
                  className="board-edit-button"
                  title="Edit Board"
                  onClick={() => handleEditButtonClick()}
                >
                  <i data-feather="edit" key="edit-icon"></i>
                </button>
                <button
                  className="board-delete-button"
                  onClick={() => handleDeleteBoard(boardId)}
                  title="Delete Board"
                >
                  <i data-feather="trash-2"></i>
                </button>
              </div>
              <ImagePromptInput onGenerate={onGenerate} />
              <div className="board-images">
                {boardImagesReversed.map((imageObject, index) => (
                  <ImageCard key={index} image={imageObject} />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Board;
