// Binder.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  getBinder,
  getBoardsByBinderId,
  deleteBinder,
  updateBinder,
} from "../../../services/firestoreService";
import { useBinders } from "../context/BinderContext";
import "./Binder.css";

function Binder() {
  const { binderId } = useParams();
  const navigate = useNavigate();
  const { removeBinder } = useBinders();
  const { updateBinderList } = useBinders();
  const { binders } = useBinders();
  const binderFromContext = binders.find((b) => b.id === binderId);

  const [editMode, setEditMode] = useState(false);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [binder, setBinder] = useState(null);
  const [boards, setBoards] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isSubscribed = true;
    const fetchBinder = async () => {
      

      if (!binderFromContext && isSubscribed) {
        try {
          setLoading(true);
          const binderData = await getBinder(binderId);
          if (isSubscribed) {
            setBinder(binderData);
            if (binderData) {
              const fetchedBoards = await getBoardsByBinderId(binderId);
              if(isSubscribed){
                setBoards(fetchedBoards);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching binder: ", error);
        } finally {
          if (isSubscribed) {
            setLoading(false);
          }
        }
      } else {
        // Use the binder from context
        setBinder(binderFromContext);
        try {
          setLoading(true);
          const fetchedBoards = await getBoardsByBinderId(binderId);
          if (isSubscribed) {
            setBoards(fetchedBoards);
          }
        } catch (error) {
          console.error("Error fetching boards: ", error);
        } finally {
          if (isSubscribed) {
            setLoading(false);
          }
        }
      }
  }

    fetchBinder();
    return () => {
      isSubscribed = false; // Set to false when component unmounts
    };
  }, [binderId, binderFromContext]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!binder) {
    return <div>Binder not found</div>;
  }

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this binder?"
    );
    if (confirmDelete) {
      try {
        await deleteBinder(binderId);
        removeBinder(binderId);
        navigate("/"); // navigate to home or another appropriate route
      } catch (error) {
        console.error("Error deleting binder: ", error);
        alert("Failed to delete binder");
      }
    }
  };

  const handleUpdate = async () => {
    try {
      await updateBinder(binderId, {
        name: newName,
        description: newDescription,
      });
      updateBinderList(binderId, {
        name: newName,
        description: newDescription,
      }); // Update context if needed
      setEditMode(false); // Exit edit mode
    } catch (error) {
      console.error("Error updating binder: ", error);
      alert("Failed to update binder");
    }
  };

  return (
    <div className="binder-container">
      {editMode ? (
        // Edit mode view
        <div>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Binder Name"
          />
          <textarea
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            placeholder="Description"
          />
          <button onClick={handleUpdate}>Update Binder</button>
          <button onClick={() => setEditMode(false)}>Cancel</button>
        </div>
      ) : (
        // Regular view
        <>
          <h2 className="binder-title">{binder.name}</h2>
          <div className="binder-description">{binder.description}</div>
          <button
            onClick={() => navigate("/create-board", { state: { binderId } })}
          >
            Create New Board
          </button>
          {boards.length > 0 && (
            <div className="binder-board-list">
              <h3>Boards</h3>
              {boards.map((board) => (
                <Link
                  to={`/board/${board.id}`}
                  key={board.id}
                  className="board-link"
                >
                  <div className="binder-board-item">
                    {board.title || "Unnamed Board"}
                  </div>
                </Link>
              ))}
            </div>
          )}
          <button onClick={handleDelete}>Delete Binder</button>
          <button
            onClick={() => {
              setEditMode(true);
              setNewName(binder.name);
              setNewDescription(binder.description);
            }}
          >
            Edit Binder
          </button>
        </>
      )}
    </div>
  );
}

export default Binder;
