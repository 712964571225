import "./App.css";
import "./fonts.css";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./firebase";
import { Route, Routes } from "react-router-dom";
import CreateBoard from "./components/Board/CreateBoard/CreateBoard";
import { useNavigate, Link } from "react-router-dom";
import BoardList from "./components/BoardList/BoardList";
import Board from "./components/Board/Board";
import AuthForm from "./components/Auth/AuthForm";
import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { getAuth } from "firebase/auth";
import UserProfile from "./components/UserProfile/UserProfile";
import SplashPage from "./components/SplashPage/SplashPage";
import CreateBinder from "./components/Binder/CreateBinder/CreateBinder";
import { BinderProvider } from "./components/Binder/context/BinderContext";
import BinderList from "./components/Binder/BinderList/BinderList";
import Binder from './components/Binder/Binder/Binder';

function App() {
  const [user, setUser] = useState(null);
  const [boards, setBoards] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("User state changed: ", currentUser);
      setUser(currentUser);
      if (currentUser) {
        const fetchBoards = async () => {
          const q = query(
            collection(db, "boards"),
            where("userId", "==", currentUser.uid)
          );
          const querySnapshot = await getDocs(q);
          const boardsArray = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setBoards(boardsArray);
        };
        fetchBoards().catch(console.error);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    // Cleanup subscription on unmount
    return () => {
      unsubscribe();
    };
  }, []);

  const navigate = useNavigate();
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        navigate("/signin");
      })
      .catch((error) => {
        console.error(`handleSignOut error: ${error}`);
      });
  };

  return (
    <div className="app-container">
      {/* Header bar across the full width */}
      <div className="header">
        <h1 className="logo">ideo</h1>
        <div className="user-info">
          {user && (
            <>
              <span className="user-email">
                <Link to="/user-profile" className="user-email">
                  {user.email}
                </Link>
              </span>
              <button className="sign-out" onClick={handleSignOut}>
                Sign Out
              </button>
            </>
          )}
        </div>
      </div>

      {/* Conditionally render the main content if the user is signed in */}
      {user ? (
        <BinderProvider userId={user ? user.uid : null}>
          <div className="main-content">
            {/* Sidebar */}
            <div className="sidebar">
              <BinderList />
              {/* Removed Routes from here */}
            </div>

            {/* Right content area */}
            <div className="right-content">
              <Routes>
                <Route
                  path="/create-board"
                  element={<CreateBoard setBoards={setBoards} />}
                />
                <Route
                  path="/board-list"
                  element={<BoardList boards={boards} />}
                />
                <Route path="/board/:boardId" element={<Board />} />
                <Route path="/auth" element={<AuthForm />} />
                <Route
                  path="/user-profile"
                  element={<UserProfile user={user} />}
                />
                <Route path="/create-binder" element={<CreateBinder />} />
                <Route path="/binder/:binderId" element={<Binder />} />
                {/* Add other routes as necessary */}
              </Routes>
            </div>
          </div>
        </BinderProvider>
      ) : (
        // If the user is not signed in
        <SplashPage />
      )}
    </div>
  );
}

export default App;
