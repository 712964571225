import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./BoardList.css";
import feather from "feather-icons";

const BoardList = ({ boards }) => {
  useEffect(() => {
    feather.replace();
  }, []);

  return (
    <div className="board-container">
      <div className="board-header-container">
        <h2 className="board-header">Boards</h2>
        <Link
          to="/create-board"
          className="create-board-button"
          title="Create New Board!"
        >
          <i data-feather="plus-square"></i>
        </Link>
      </div>
      <ul className="board-list">
        {boards.map((board) => (
          <li className="board-item" key={board.id}>
            <Link className="board-link" to={`/board/${board.id}`}>
              {board.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BoardList;
