import React, { useState } from "react";
import { signUp } from "../../auth-service"; // Adjust the path if necessary
import "./Auth.css";
import { createUserProfile } from "../../services/firestoreService";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(""); // Add state for first name
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const userCredential = await signUp(email, password);
      await createUserProfile(userCredential.user, { firstName, lastName });
      // Navigate to sign-in page or dashboard page upon successful registration
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="sign-up-container">
      <h2 className="sign-up-header">Sign Up</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form className="sign-up-form" onSubmit={handleSubmit}>
        <input
          className="sign-up-input"
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          className="sign-up-input"
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <input
          className="sign-up sign-up-email"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="sign-up sign-up-password"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="sign-up-button" type="submit">
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default SignUp;
