import React, { useState, useEffect } from "react";
import "./ImagePromptInput.css"; 

function ImagePromptInput({ onGenerate }) {
  const [prompt, setPrompt] = useState("");
  const [model, setModel] = useState("dall-e-2");
  const [size, setSize] = useState("256x256");

  // Update available sizes based on the model chosen
  useEffect(() => {
    if (model === "dall-e-2") {
      setSize("256x256");
    } else if (model === "dall-e-3") {
      setSize("1024x1024");
    }
  }, [model]);

  // Updates the size state
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  // Updates the model state and resets size if needed
  const handleModelChange = (e) => {
    const newModel = e.target.value;
    setModel(newModel);

    // Reset size when changing models, if necessary
    if (
      newModel === "dall-e-2" &&
      size !== "256x256" &&
      size !== "512x512" &&
      size !== "1024x1024"
    ) {
      setSize("256x256"); // Default to the smallest size for DALL-E 2
    } else if (
      newModel === "dall-e-3" &&
      size !== "1024x1024" &&
      size !== "1024x1792" &&
      size !== "1792x1024"
    ) {
      setSize("1024x1024"); // Default to the only size for DALL-E 3
    }
  };

  const handleInputChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (prompt) {
      onGenerate(prompt, model, size);
      setPrompt("");
    } else {
      alert("Please enter a prompt.");
    }
  };

  return (
    <form className="image-prompt-form" onSubmit={handleSubmit}>
      <div className="input-container">
        <input
          type="text"
          className="image-prompt-input"
          value={prompt}
          onChange={handleInputChange}
          placeholder="Enter your design prompt"
        />
        <button type="submit" className="image-prompt-submit">
          Generate
        </button>
      </div>
      <div className="controls-container">
        <select
          className="size-dropdown"
          value={size}
          onChange={handleSizeChange}
        >
          {/* Options will be conditionally rendered based on the model selected */}
          {model === "dall-e-2" ? (
            <>
              <option value="256x256">256x256</option>
              <option value="512x512">512x512</option>
              <option value="1024x1024">1024x1024</option>
            </>
          ) : (
            <>
              <option value="1024x1024">1024x1024</option>
              <option value="1024x1792">1024x1792</option>
              <option value="1792x1024">1792x1024</option>
            </>
          )}
        </select>
        <div className="model-selector">
          <label className="model-label">
            <input
              type="radio"
              className="model-radio"
              value="dall-e-2"
              checked={model === "dall-e-2"}
              onChange={handleModelChange}
            />
            DALL-E 2
          </label>
          <label className="model-label">
            <input
              type="radio"
              className="model-radio"
              value="dall-e-3"
              checked={model === "dall-e-3"}
              onChange={handleModelChange}
            />
            DALL-E 3
          </label>
        </div>
      </div>
    </form>
  );
}

export default ImagePromptInput;
